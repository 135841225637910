import { Popper } from "@mui/material";
import type { FC, HTMLProps } from "react";
import { Children, isValidElement, useRef, useState } from "react";

import { useIsomorphicLayoutEffect } from "@shared/hooks";

import { DropdownCategory } from "../DropdownCategory";
import { useDropdownContext } from "../DropdownContext";

import * as styles from "./DropdownContent.styles";

let DropdownCategoryType;

const getDropdownCategoryType = () => {
  if (!DropdownCategoryType) {
    DropdownCategoryType = (<DropdownCategory text="nada" categoryId="nuh uh" />).type;
  }
  return DropdownCategoryType;
};

const DropdownContent: FC<HTMLProps<HTMLDivElement>> = ({ children, ...rest }) => {
  const {
    activeCategoryId,
    isDropdownOpen,
    onTriggerClicked,
    setMouseOverContent,
    isFullWidth,
    isFullHeight,
    triggerElement,
    dropdownPlacement,
  } = useDropdownContext();

  const ref = useRef(null);
  const useLayoutEffect = useIsomorphicLayoutEffect();

  const [scrollTopToRestore, setScrollTopToRestore] = useState<number>();

  useLayoutEffect(() => {
    if (ref?.current) {
      if (activeCategoryId) {
        setScrollTopToRestore(ref.current.scrollTop);
        ref.current.scrollTop = 0;
      } else if (scrollTopToRestore >= 0) {
        ref.current.scrollTop = scrollTopToRestore;
      }
    }
  }, [activeCategoryId]);

  if (!isDropdownOpen || !triggerElement) return null;

  if (isDropdownOpen && !triggerElement) {
    onTriggerClicked();
    return null;
  }

  const onMouseEnterHandler = () => {
    setMouseOverContent(true);
  };

  const onMouseLeaveHandler = () => {
    setMouseOverContent(false);
  };

  const conditionalStyle = () => {
    return [
      styles.dropdownContent,
      isFullWidth && styles.isFullWidth,
      isFullHeight && styles.isFullHeight,
    ];
  };

  const popperStyles = [styles.popper, isFullWidth && styles.isFullWidth];

  const renderContent = () => {
    if (activeCategoryId) {
      const childs = Children.toArray(children);

      return childs.find(child => {
        if (isValidElement(child) && child.type === getDropdownCategoryType()) {
          return child.props?.categoryId === activeCategoryId;
        }
        return false;
      });
    }
    return children;
  };

  return (
    <Popper
      placement={dropdownPlacement}
      open={isDropdownOpen}
      disablePortal={true}
      anchorEl={triggerElement}
      css={popperStyles}
    >
      <div
        css={conditionalStyle()}
        onMouseEnter={onMouseEnterHandler}
        onMouseLeave={onMouseLeaveHandler}
        data-testid="dropdown-content"
        ref={ref}
        {...rest}
      >
        {renderContent()}
      </div>
    </Popper>
  );
};

export { DropdownContent };
