import { createContext } from "react";

interface ServerData {
  url: string;
  deviceType?: string;
}

const DefaultServer: ServerData = {
  url: "",
  deviceType: "desktop",
};

const ServerContext = createContext<ServerData>(DefaultServer);

export type { ServerData };
export { ServerContext, DefaultServer };
