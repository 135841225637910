import type { Dispatch, SetStateAction } from "react";

import type { PopperPositionType } from "@aviary/types/popperPositions";

enum OpenAction {
  CLICK = "click",
  HOVER = "hover",
}

enum EllipsesPosition {
  TOPRIGHT = "topRight",
  BOTTOMRIGHT = "bottomRight",
  BOTTOMLEFT = "bottomLeft",
  TOPLEFT = "topLeft",
  DEFAULT = "default",
}

interface ItemSelectOptions {
  closeOnItemSelect?: boolean;
}

interface DropdownInternals {
  activeCategoryId: string;
  setActiveCategoryId: (category?: string) => void;
  isDropdownOpen: boolean;
  triggerElement?: Element;
  setTriggerElement?: Dispatch<SetStateAction<Element>>;
  onItemSelected?: (callback: () => void, options?: ItemSelectOptions) => void;
  onTriggerClicked?: () => void;
  prefixText: string;
  openAction: OpenAction;
  mouseOverContent: boolean;
  setMouseOverContent: Dispatch<SetStateAction<boolean>>;
  mouseOverTrigger: boolean;
  setMouseOverTrigger: Dispatch<SetStateAction<boolean>>;
  triggerFocused: boolean;
  setTriggerFocused: Dispatch<SetStateAction<boolean>>;
  itemFocused: boolean;
  setItemFocused: Dispatch<SetStateAction<boolean>>;
  isDisabled: boolean;
  isFullWidth: boolean;
  isFullHeight: boolean;
  ellipsesPosition: EllipsesPosition;
  dropdownPlacement: PopperPositionType;
}

export type { DropdownInternals, ItemSelectOptions };
export { OpenAction, EllipsesPosition };
