import { css } from "@emotion/react";

import { colors } from "@styles";

export const dropdownDivider = css`
  width: calc(100% + 1rem);
  height: 0.125rem;
  background-color: ${colors.blue.lightFamily.normal};
  margin: 0rem -0.5rem 0.25rem;
`;

export const marginedTop = css`
  margin: 0.25rem -0.5rem 0.25rem;
`;
