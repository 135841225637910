import NextHead from "next/head";
import { FC } from "react";

interface Props {
  title: string;
}

const Head: FC<Props> = ({ title, children }) => {
  return (
    <NextHead>
      <title>{title}</title>
      {children}
    </NextHead>
  );
};

export { Head };
