import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { FC, HTMLProps, KeyboardEvent } from "react";

import { useDropdownContext } from "../DropdownContext";

import * as styles from "./DropdownItem.styles";

interface Props extends HTMLProps<HTMLDivElement> {
  /**
   * Sets implementation of boilerplate styles
   */
  noStyle?: boolean;
  /**
   * Sets if item is currently active
   */
  isHighlighted?: boolean;
  /**
   * Hides the spacer for month selector
   */
  hideSpacer?: boolean;
  /**
   * Callback fires when component is selected
   */
  onSelect?: () => void;
  /**
   *  If true, the Dropdown will close when this item is selected.
   *  If defined, will override Dropdown component's closeOnItemSelect prop
   */
  closeOnItemSelect?: boolean;
  /**
   * Sets text justification
   *
   * @default right
   */
  textDirection?: "left" | "right";
  /**
   * sets if item can be selected
   */
  disabled?: boolean;
  /**
   * sets color theme
   *
   * @default "default"
   */
  isColor?: "default" | "danger";
  /**
   * An icon to be rendered at the end of the dropdown item
   */
  icon?: IconDefinition;
  /**
   * Customizes the location of the icon
   *
   * @default end
   */
  iconLocation?: "start" | "end";
  /**
   * Removes the width truncation text limit
   *
   */
  noTruncation?: boolean;
}

const DropdownItem: FC<Props> = ({
  children,
  closeOnItemSelect,
  disabled,
  isColor,
  isHighlighted,
  hideSpacer,
  noStyle,
  onSelect,
  textDirection,
  icon,
  iconLocation,
  noTruncation,
  ...rest
}) => {
  const { onItemSelected, setItemFocused, isFullWidth } = useDropdownContext();

  const getTabIndex = () => (disabled ? -1 : 0);

  const clickHandler = () => {
    selectHandler();
  };

  const keyPressHandler = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      selectHandler();
    }
  };

  const getOptions = () => ({
    closeOnItemSelect,
  });

  const selectHandler = () => {
    if (!disabled) {
      onItemSelected(onSelect, getOptions());
    }
  };

  const handleFocus = () => {
    setItemFocused(true);
  };

  const handleBlur = () => {
    setItemFocused(false);
  };

  const conditionalStyles = () =>
    !noStyle && [
      styles.dropdownItem,
      styles.color[isColor],
      styles.textDirection[textDirection],
      disabled && styles.disabled,
      isFullWidth && styles.fullwidth,
      isHighlighted && styles.highlighted,
      noTruncation && styles.noTruncation,
    ];

  const textStyles = () => {
    return [styles.truncateChild, noTruncation && styles.noTruncation];
  };

  const renderIcon = (location: string) => {
    if (location !== iconLocation) return;
    return (
      !hideSpacer &&
      icon && (
        <div data-testid="spacer" css={[styles.spacer.base, styles.spacer[iconLocation]]}>
          {icon && <FontAwesomeIcon icon={icon} />}
        </div>
      )
    );
  };

  return (
    <div
      css={conditionalStyles()}
      tabIndex={getTabIndex()}
      onKeyPress={keyPressHandler}
      onClick={clickHandler}
      onFocus={handleFocus}
      onBlur={handleBlur}
      role="button"
      {...rest}
    >
      {renderIcon("start")}
      <div css={textStyles()}>{children}</div>
      {renderIcon("end")}
    </div>
  );
};

DropdownItem.defaultProps = {
  isColor: "default",
  textDirection: "left",
  iconLocation: "end",
};

export { DropdownItem };
