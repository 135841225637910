import { MutableRefObject, useEffect, useLayoutEffect as reactUseLayoutEffect } from "react";

// This is for SSR purposes, useLayoutEffect is not supported on the server side
const useLayoutEffect = typeof window === "undefined" ? useEffect : reactUseLayoutEffect;

const useOutsideClick = <T extends HTMLElement>(
  ref: MutableRefObject<T>,
  onClick: (e: MouseEvent) => void
) => {
  useLayoutEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref?.current && !ref.current.contains(e.target as any)) {
        onClick(e);
      }
    };
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [onClick]);
};

export { useOutsideClick };
