import { css } from "@emotion/react";

import { colors, dimensions, layers } from "@styles";
import { hexToRgba } from "@styles/helpers";

export const dropdownContent = css`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 10.25rem;
  padding: 0.5rem;
  background-color: ${colors.white};
  border-radius: ${dimensions.borderRadius};
  box-shadow: 0 1px 8px 0 ${hexToRgba(colors.grey.base, 0.25)};
  z-index: ${layers.indexDropdownContent};
`;

export const popper = css`
  z-index: ${layers.indexDropdownContent};
  max-width: calc(100vw - 0.5rem);
  position: absolute;
`;

export const isFullWidth = css`
  min-width: 100% !important;
`;

export const isFullHeight = css`
  max-height: none;
  height: 100%;
`;

export const opensUp = css`
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 1px;
`;
