import type { FC, HTMLAttributes } from "react";
import { useEffect, useRef, useState } from "react";

import type { PopperPositionType } from "@aviary/types/popperPositions";
import { POPPER_POSITIONS } from "@aviary/types/popperPositions";
import { useControlledState, useOutsideClick } from "@shared/hooks";

import { DropdownContext } from "./DropdownContext";
import type { DropdownInternals, ItemSelectOptions } from "./types";
import { OpenAction, EllipsesPosition } from "./types";

import * as styles from "./Dropdown.styles";

interface Props extends HTMLAttributes<HTMLDivElement> {
  /**
   * Optionally control the open state of Dropdown with this boolean. Use triggerCallback as the callback that closes the dropdown
   *
   */
  isOpen?: boolean;
  /**
   * Determines what cursor behaviour prompts the dropdown to open
   *
   * @default OpenAction.click
   */
  openAction?: OpenAction;
  /**
   * The delay in ms before closing the dropdown when hover OpenAction is set
   *
   * @default 150
   */
  hoverCloseDelay?: number;
  /**
   * Optionally add in a prefix to any of the dropdown triggers
   *
   */
  prefixText?: string;
  /**
   * Function that is fired whenever the trigger is pressed
   */
  triggerCallback?: (value: any) => void;
  /**
   * If true, the Dropdown will close when the cursor clicks anywhere outside the dropdown
   *
   * @default true
   */
  closeOnExternalClick?: boolean;
  /**
   * If true, the Dropdown will close when an item is selected
   *
   * @default true
   */
  closeOnItemSelect?: boolean;
  /**
   * Disallows the dropdown from being opened or used
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * Component will stretch to fill its container
   *
   * @default false
   */
  isFullWidth?: boolean;
  /**
   * Dropdown content will display it's full contents
   *
   * @default false
   */
  isFullHeight?: boolean;
  /**
   * Position the ellipse absolutely to parent container so that the icon aligns to the container while still maintaining a touch point of 36px.
   * IMPORTANT!! Parent container MUST be position: relative
   *
   * @default EllipsesPosition.DEFAULT
   */
  ellipsesPosition?: EllipsesPosition;
  /**
   * Position direction that the content will appear
   *
   * @default PopperPositionEnum.BOTTOMEND
   */
  dropdownPlacement?: PopperPositionType;
}

const defaultProps = {
  closeOnExternalClick: true,
  closeOnItemSelect: true,
  disabled: false,
  isFullWidth: false,
  isFullHeight: false,
  openAction: OpenAction.CLICK,
  hoverCloseDelay: 150,
  ellipsesPosition: EllipsesPosition.DEFAULT,
  dropdownPlacement: POPPER_POSITIONS["bottom-end"] as PopperPositionType,
};

const Dropdown: FC<Props> = ({
  children,
  closeOnExternalClick,
  closeOnItemSelect,
  isOpen,
  openAction,
  prefixText,
  triggerCallback,
  disabled,
  isFullWidth,
  isFullHeight,
  ellipsesPosition,
  dropdownPlacement,
  hoverCloseDelay,
  ...rest
}) => {
  const [activeCategoryId, setActiveCategoryId] = useState<string>();
  const [mouseOverContent, setMouseOverContent] = useState<boolean>(false);
  const [mouseOverTrigger, setMouseOverTrigger] = useState<boolean>(false);
  const [triggerFocused, setTriggerFocused] = useState<boolean>(false);
  const [itemFocused, setItemFocused] = useState<boolean>(false);
  const [triggerElement, setTriggerElement] = useState<Element>(null);
  const [hoverClosed, setHoverClosed] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useControlledState(!!isOpen, {
    value: isOpen,
    setValue: triggerCallback,
  });
  const timeoutRef = useRef(null);
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => {
    if (closeOnExternalClick && isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  });

  useEffect(() => {
    if (openAction === OpenAction.HOVER) {
      if (
        (mouseOverContent || mouseOverTrigger || triggerFocused || itemFocused) &&
        !disabled &&
        !hoverClosed
      ) {
        setIsDropdownOpen(true);
        clearTimeout(timeoutRef.current);
      } else if (isDropdownOpen && !mouseOverContent) {
        timeoutRef.current = setTimeout(() => {
          setIsDropdownOpen(false);
        }, hoverCloseDelay);
      }
    }
  }, [mouseOverContent, mouseOverTrigger, triggerFocused, itemFocused]);

  useEffect(() => {
    if (hoverClosed && !mouseOverTrigger) {
      setHoverClosed(false);
    }
  }, [mouseOverTrigger]);

  useEffect(() => {
    if (disabled) {
      setIsDropdownOpen(false);
    }
  }, [disabled]);

  const onTriggerClicked = () => {
    if (!disabled) {
      if (openAction === OpenAction.HOVER && isDropdownOpen === true) {
        setTriggerFocused(false);
        setHoverClosed(true);
      }
      setIsDropdownOpen(!isDropdownOpen);
    }
  };

  const onItemSelected = (itemCallback?: () => void, options?: ItemSelectOptions) => {
    const shouldClose = options?.closeOnItemSelect ?? closeOnItemSelect;

    if (shouldClose) {
      setItemFocused(false);
      setIsDropdownOpen(false);
    }

    if (itemCallback) {
      itemCallback();
    }
  };

  const onKeyDownHandler = event => {
    if (event.key === "Escape" && isDropdownOpen) {
      setIsDropdownOpen(false);
    }
  };

  const dropdownContext: DropdownInternals = {
    activeCategoryId,
    setActiveCategoryId,
    isDropdownOpen,
    mouseOverContent,
    mouseOverTrigger,
    triggerFocused,
    triggerElement,
    prefixText,
    setTriggerElement,
    itemFocused,
    onItemSelected,
    onTriggerClicked,
    openAction,
    setMouseOverContent,
    setMouseOverTrigger,
    setTriggerFocused,
    setItemFocused,
    isDisabled: disabled,
    isFullWidth,
    isFullHeight,
    ellipsesPosition,
    dropdownPlacement,
  };

  const conditionalDropdownStyles = [
    [styles.dropdown, ellipsesPosition && styles.position.kebab, isFullWidth && styles.isFullWidth],
  ];

  return (
    <div onKeyDown={onKeyDownHandler} ref={dropdownRef} css={conditionalDropdownStyles} {...rest}>
      <DropdownContext.Provider value={dropdownContext}>{children}</DropdownContext.Provider>
    </div>
  );
};

Dropdown.defaultProps = defaultProps;
export type { Props as DropdownProps };
export { Dropdown };
