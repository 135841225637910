import { css } from "@emotion/react";

import { colors, typography } from "@styles";

export const headerItem = css`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  width: calc(100% + 1rem);
`;

export const backIcon = css`
  margin-right: 1rem;
`;

export const strong = css`
  font-weight: ${typography.strong};
  color: ${colors.black};
`;

export const leftIconStyle = css`
  margin-right: 1rem;
`;
