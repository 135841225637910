import type { FC } from "react";

import * as styles from "./DropdownDivider.styles";

interface Props {
  /**
   * Optionally remove the top margin of the divider
   * Used when the item above it is NOT a DropdownItem/DropdownLink
   *
   */
  hasTopMargin?: boolean;
}

const DropdownDivider: FC<Props> = ({ hasTopMargin }) => {
  const dividerStyles = [styles.dropdownDivider, hasTopMargin && styles.marginedTop];
  return <div css={dividerStyles} />;
};

DropdownDivider.defaultProps = {
  hasTopMargin: true,
};

export { DropdownDivider };
