import { css } from "@emotion/react";

import { colors } from "@styles";
import { ellipsesOverflowHelper, removeClickOutline } from "@styles/emotion-styles/helpers";

export const dropdownItem = css`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  white-space: nowrap;
  width: 100%;
  max-width: 16rem;
  border-radius: 4px;
  ${removeClickOutline};
`;

export const truncateChild = css`
  width: 100%;
  max-width: 16rem;
  ${ellipsesOverflowHelper};
`;

export const noTruncation = css`
  max-width: none;
`;

export const color = {
  default: css`
    background: ${colors.white};
    color: ${colors.grey.base};
    border: 1px solid transparent;

    &:hover {
      background-color: ${colors.grey.lightFamily.normal};
      color: ${colors.grey.darkFamily.dark};
    }
    &:focus {
      color: ${colors.black};
      background: ${colors.grey.lightFamily.normal};
      border: 1px solid ${colors.blue.darkFamily.normal};
    }
    &:active {
      color: ${colors.black};
      background: ${colors.grey.lightFamily.normal};
    }
  `,
  danger: css`
    background: ${colors.white};
    color: ${colors.danger};
    border: 1px solid transparent;

    &:hover {
      background-color: ${colors.red.lightFamily.light};
      color: ${colors.danger};
    }
    &:focus {
      color: ${colors.red.darkFamily.darker};
      border: 1px solid ${colors.red.base};
      background-color: ${colors.red.lightFamily.light};
    }
    &:active {
      color: ${colors.red.darkFamily.darker};
      border: 1px solid ${colors.blue.darkFamily.normal};
      background-color: ${colors.red.lightFamily.normal};
    }
  `,
};

export const disabled = css`
  cursor: not-allowed;
  color: ${colors.grey.lightFamily.dark};

  &:hover {
    background-color: inherit;
    color: ${colors.grey.lightFamily.dark};
  }
`;

export const highlighted = css`
  background-color: ${colors.grey.lightFamily.light};
`;

export const fullwidth = css`
  max-width: none;
`;

export const textDirection = {
  left: css`
    text-align: left;
  `,
  right: css`
    flex-direction: row-reverse;
    text-align: right;
  `,
};

export const spacer = {
  base: css`
    align-items: center;
    display: flex;
    justify-content: flex-end;
  `,
  start: css`
    margin-right: 1rem;
  `,
  end: css`
    min-width: 2rem;
  `,
};
