import type { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { faAngleRight, faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@aviary/components/Typography";
import { l } from "@aviary/locales/i18n";

import { useDropdownContext } from "../DropdownContext";
import { DropdownDivider } from "../DropdownDivider";
import { DropdownItem } from "../DropdownItem";

import * as styles from "./DropdownCategory.styles";

interface Props {
  /**
   * The string passed here will be displayed on the dropdown item that leads into the category
   *
   * @required
   */
  text: string;
  /**
   * A unique key that is used to distinguish categories
   *
   * @required
   */
  categoryId: string;
  /**
   * Optional prop to override the return button text
   */
  backText?: string;
  /**
   * Optional Icon to display beside text
   */
  leftIcon?: IconDefinition;
  /**
   * The categorized content
   *
   * @default undefined
   */
  children?: ReactNode;
}

const DropdownCategory: FC<Props> = ({ children, text, backText, categoryId, leftIcon }) => {
  const { t } = useTranslation();
  const { activeCategoryId, setActiveCategoryId } = useDropdownContext();

  const goBackHandler = () => {
    setActiveCategoryId();
  };

  const selectHandler = () => {
    setActiveCategoryId(categoryId);
  };

  if (activeCategoryId === categoryId) {
    return (
      <>
        <DropdownItem
          aria-label={t(l.aviary.dropdown.GoBack)}
          data-testid="back-button"
          onSelect={goBackHandler}
          closeOnItemSelect={false}
          css={styles.headerItem}
          icon={faAngleLeft}
          iconLocation="start"
        >
          <Typography css={styles.strong}>{backText ? backText : text}</Typography>
        </DropdownItem>
        <DropdownDivider hasTopMargin={false} />
        {children}
      </>
    );
  }

  return (
    <DropdownItem onSelect={selectHandler} closeOnItemSelect={false} icon={faAngleRight}>
      {leftIcon && (
        <FontAwesomeIcon
          data-testid="leftIcon"
          css={styles.leftIconStyle}
          size="sm"
          icon={leftIcon}
        />
      )}
      {text}
    </DropdownItem>
  );
};

export { DropdownCategory };
